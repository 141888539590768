<template>
  <div v-if="listPositions.length > 0" class="page-positions shared-index">
    <h3 class="m-0">Bảng giá theo vị trí banner</h3>
    <el-row class="table-title" type="flex" align="center" justify="space-between">
      <div class="table-title__left">
        <el-tabs v-model="activeTab" type="card">
          <el-tab-pane
            v-for="position in listPositions"
            :key="position.id"
            :name="`${position.id}`"
          >
            <div slot="label">
              {{ `Banner ${position.id}` }}
              <span v-if="`${position.id}` === activeTab">({{ total }})</span>
            </div>
          </el-tab-pane>
        </el-tabs>
        <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          size="small"
          @click="handleOpenFormPopup()"
        >Thêm giá</el-button>
      </div>
    </el-row>
    <div v-loading="callingAPI" class="table">
      <list-prices
        :data="prices"
        :loading="loading"
        @delete="handleDelete"
        @edit="handleOpenFormPopup"
      ></list-prices>
    </div>
    <price-form-popup
      v-if="visible"
      :visible="visible"
      :price="price"
      @updatePrice="handleUpdatePrice"
      @close="visible = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ListPrices from './prices/List'
import PriceFormPopup from './popups/PriceForm.vue'

import { updateBanner } from '@/services/banner'

export default {
  name: 'PagePrices',
  components: {
    ListPrices,
    PriceFormPopup
  },
  props: {
    page: Object
  },
  data() {
    return {
      activeTab: null,
      loading: false,
      visible: false,
      price: null,
      callingAPI: false
    }
  },
  computed: {
    ...mapGetters(['language']),
    listPositions() {
      return this.page.meta.positions
    },
    currentPosition() {
      return this.listPositions.find((p) => p.id === Number(this.activeTab || 0)) || {
        prices: []
      }
    },
    prices() {
      return (this.currentPosition.prices || []).sort((a, b) => a.days - b.days)
    },
    total() {
      return this.prices.length
    },
    pageString() {
      return JSON.stringify(this.page)
    }
  },

  watch: {
    pageString: 'loadData'
  },

  beforeMount() {
    this.loadData()
  },

  methods: {
    loadData() {
      if (!this.activeTab || !this.page.meta.positions.find((p) => `${p.id}` === this.activeTab)) {
        this.activeTab = this.page.meta.positions.length > 0 ? `${this.page.meta.positions[0].id}` : null
      }
    },
    handleOpenFormPopup(price = null) {
      this.price = price
      this.visible = true
    },

    handleUpdatePositions(ps) {
      updateBanner(this.page.id, {
        id: this.page.id,
        meta: {
          positions: ps
        }
      }).then(() => {
        this.$notify.success({
          title: 'Thành công',
          message: 'Cập nhật thành công'
        })
        this.visible = false
        this.page.meta.positions = ps
      }).catch(() => {
        this.$notify.error({
          title: 'Thất bại',
          message: 'Cập nhật thất bại'
        })
      })
    },

    handleUpdatePrice(newPrice) {
      let newPrices = []
      if (this.price) {
        newPrices = this.prices.map((p) => this.price.id === p.id ? newPrice : p)
      } else {
        newPrices = [...this.prices, newPrice]
      }
      this.handleUpdatePrices(newPrices)
    },

    handleUpdatePrices(newPrices) {
      const positions = this.listPositions.map((p) => p.id === this.currentPosition.id ? {
        ...this.currentPosition,
        prices: newPrices
      } : p)
      this.handleUpdatePositions(positions)
    },

    handleDelete(price) {
      this.$confirm('Bạn có chắc chắn muốn xoá giá này không?', 'Cảnh báo', {
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Hủy',
        type: 'warning'
      }).then(() => {
        const newPrices = this.prices.filter((p) => p.id !== price.id)
        this.handleUpdatePrices(newPrices)
      }).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
