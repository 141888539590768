<template>
  <el-dialog
    top="80px"
    width="600px"
    class="package-form"
    :title="page ? 'Tạo trang banner mới' : 'Chỉnh sửa trang banner'"
    :visible="visible"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-form ref="form" :model="form" :rules="rules" label-position="top">
      <el-form-item label="Tên banner" prop="label">
        <el-input
          v-model="form.label"
          :disabled="callingAPI"
          placeholder="Nhập tên"
        ></el-input>
      </el-form-item>
      <el-form-item label="Trang hiển thị" prop="label_short">
        <el-select v-model="form.label_short" class="w-100" :disabled="!!page">
          <el-option
            v-for="p in pageIds"
            :key="p.id"
            :label="p.link"
            :value="p.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Mô tả" prop="description">
        <el-input
          v-model="form.description"
          type="textarea"
          :disabled="callingAPI"
          placeholder="Nhập mô tả"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="handleClose">Hủy</el-button>
      <el-button
        v-if="!page"
        type="primary"
        :loading="callingAPI"
        @click="handleCreateBanner"
      >Tạo mới</el-button>
      <el-button
        v-else
        type="primary"
        :loading="callingAPI"
        @click="handleUpdateBanner"
      >Lưu</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { createBanner, updateBanner } from '@/services/banner'
import CONSTANTS from '@/config/constants'
export default {
  name: 'PageForm',
  props: {
    visible: Boolean,
    page: Object
  },
  data() {
    return {
      callingAPI: false,
      pageIds: CONSTANTS.PAGE_IDS,
      form: {
        label: this.page ? this.page.label : '',
        label_short: this.page ? this.page.label_short : '',
        description: this.page ? this.page.description : ''
      },
      rules: {
        label: [
          { required: true, message: 'Tên không được để trống', trigger: ['blur', 'change'] }
        ],
        label_short: [
          { required: true, message: 'ID không được để trống', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['language'])
  },
  methods: {
    handleCreateBanner() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.callingAPI = true
          const data = {
            ...this.form,
            link: this.pageIds.find((p) => p.id === this.form.label_short).link,
            banner_type: 'private',
            meta: {
              positions: []
            }
          }
          await createBanner(data).then(() => {
            this.$notify.success({
              title: 'Thành công',
              message: 'Tạo mới banner thành công'
            })
            this.$refs.form.resetFields()
            this.$emit('reload')
            this.$emit('close')
            this.callingAPI = false
          }).catch(() => {
            this.$notify.error({
              title: 'Thất bại',
              message: 'Tạo mới banner thất bại'
            })
            this.callingAPI = false
          })
        }
      })
    },

    handleUpdateBanner() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.callingAPI = true
          const data = {
            id: this.page.id,
            ...this.form
          }
          await updateBanner(this.page.id, data).then(() => {
            this.$notify.success({
              title: 'Thành công',
              message: 'Chỉnh sửa trang banner thành công'
            })
            this.$refs.form.resetFields()
            this.$emit('reload')
            this.$emit('close')
            this.callingAPI = false
          }).catch(() => {
            this.$notify.error({
              title: 'Thất bại',
              message: 'Chỉnh sửa trang banner thất bại'
            })
            this.callingAPI = false
          })
        }
      })
    },

    handleClose() {
      this.$refs.form.resetFields()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped></style>
<style lang="scss">
</style>
