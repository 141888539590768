<template>
  <el-dialog
    top="80px"
    width="600px"
    class="create-user-popup"
    :title="position ? 'Thay đổi thông tin' : 'Thêm vị trí Banner'"
    :visible="visible"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-form ref="form" :model="form" label-position="top">
      <el-form-item label="Tên vị trí" prop="summary">
        <el-select
          v-model="form.id"
          :disabled="callingAPI"
          placeholder="Chọn vị trí"
          class="w-100"
        >
          <el-option
            v-for="(positionId, index) in positionIds"
            :key="index"
            :label="`Banner ${positionId}`"
            :value="positionId"
            :disabled="!!positions.find((p) => p.id === positionId)"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Đối tượng hiển thị" prop="options">
        <el-select
          v-model="form.options"
          multiple
          filterable
          :disabled="callingAPI"
          placeholder="Chọn đối tượng hiển thị"
          class="w-100"
        >
          <el-option
            v-for="(place, index) in places"
            :key="index"
            :label="place.name"
            :value="place.value"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="handleClose">Hủy</el-button>
      <el-button type="primary" :loading="callingAPI" @click="handleUpdatePosition()">Lưu</el-button>
    </span>
  </el-dialog>
</template>

<script>
import TYPE from '@/data/type'
import CONSTANTS from '@/config/constants'
import dayjs from 'dayjs'
export default {
  name: 'PositionFormPopup',
  props: {
    visible: Boolean,
    positions: Array,
    position: Object
  },
  data() {
    return {
      callingAPI: false,
      places: TYPE.PLACES,
      positionIds: CONSTANTS.POSITION_IDS,
      form: {
        id: this.position ? this.position.id : CONSTANTS.POSITION_IDS.filter((id) => !this.positions.map((p) => p.id).includes(id))[0] || null,
        options: this.position ? this.position.options : []
      }
    }
  },
  computed: {
  },
  methods: {
    handleUpdatePosition() {
      let results = []
      if (this.position) {
        results = this.positions.map((p) => ({
          ...p,
          id: p.id === this.position.id ? this.form.id : p.id,
          options: p.id === this.position.id ? this.form.options : p.options
        }))
      } else {
        results = [...this.positions, { ...this.form, prices: [
          {
            id: dayjs().unix(),
            days: 360,
            price: 1000000
          }
        ] }]
      }
      this.$emit('updatePositions', results)
    },

    handleClose() {
      this.$refs.form.resetFields()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped></style>
<style lang="scss">
</style>
