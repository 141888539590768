<template>
  <div class="org-users shared-show shared-index">
    <h3 class="m-0">
      <el-row type="flex" align="middle" justify="space-between">
        Thông tin trang banner
        <el-tooltip content="Chỉnh sửa" placement="top">
          <el-button
            icon="el-icon-edit"
            type="primary"
            circle
            size="small"
            @click="() => formVisible = true"
          ></el-button>
        </el-tooltip>
      </el-row>
    </h3>
    <div class="table">
      <p>
        <span>Tên trang: </span>
        <b class="text-primary">{{ page.label }}</b>
      </p>
      <p>
        <span>Mô tả: </span>
        <b class="text-primary">{{ page.description }}</b>
      </p>
      <p>
        <span>Link truy cập: </span>
        <a class="text-primary" :href="page.link" target="_blank">{{ page.link }}</a>
      </p>
      <p class="flex align-items-center">
        <span>Trạng thái: </span>
        <el-switch
          v-model="isPublic"
          @change="(v) => updateStatus(v)"
        >
        </el-switch>
      </p>
    </div>
    <form-popup
      v-if="formVisible"
      :visible="formVisible"
      :page="page"
      @close="formVisible = false"
      @reload="() => $emit('reload')"
    />
  </div>
</template>

<script>
import FormPopup from '../components/popups/Form.vue'
import { updateBanner } from '@/services/banner'

export default {
  name: 'OrgInformation',
  components: {
    FormPopup
  },
  props: {
    page: Object
  },
  data() {
    return {
      isPublic: this.page.banner_type === 'public',
      formVisible: false
    }
  },
  computed: {},
  methods: {
    updateStatus(isPublic) {
      const data = {
        id: this.page.id,
        banner_type: isPublic ? 'public' : 'private'
      }
      updateBanner(this.page.id, data).then(() => {
        this.$notify.success({
          title: 'Thành công',
          message: 'Cập nhật thành công'
        })
      }).catch(() => {
        this.$notify.error({
          title: 'Thất bại',
          message: 'Cập nhật thất bại'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
