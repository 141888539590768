<template>
  <el-dialog
    top="80px"
    width="400px"
    class="create-user-popup"
    :title="price ? 'Thay đổi thông tin' : 'Thêm vị trí Banner'"
    :visible="visible"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-form ref="form" :model="form" label-position="top">
      <el-form-item label="Giá tiền / 1 bài viết" prop="price">
        <el-input-number
          v-model="form.price"
          class="w-100"
          :min="1"
          :disabled="callingAPI"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="Số ngày hiển thị" prop="days">
        <el-input-number
          v-model="form.days"
          class="w-100"
          :min="1"
          :disabled="callingAPI"
        ></el-input-number>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="handleClose">Hủy</el-button>
      <el-button type="primary" :loading="callingAPI" @click="handleUpdatePrice()">Lưu</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dayjs from 'dayjs'
export default {
  name: 'PriceFormPopup',
  props: {
    visible: Boolean,
    price: Object
  },
  data() {
    return {
      callingAPI: false,
      form: this.price ? this.price : {
        id: dayjs.unix(),
        price: 0,
        days: 7
      }
    }
  },
  computed: {
  },
  methods: {
    handleUpdatePrice() {
      this.$emit('updatePrice', this.form)
    },

    handleClose() {
      this.$refs.form.resetFields()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped></style>
<style lang="scss">
</style>
