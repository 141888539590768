<template>
  <div class="page-positions shared-index">
    <h3 class="m-0">Danh sách vị trí</h3>
    <el-row class="table-title" type="flex" align="center" justify="space-between">
      <div class="table-title__left">
        <el-tabs value="positions" type="card">
          <el-tab-pane name="positions">
            <div slot="label">
              Vị trí trang banner
              <span>({{ total }})</span>
            </div>
          </el-tab-pane>
        </el-tabs>
        <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          size="small"
          :disabled="positionIds.length === total"
          @click="handleOpenFormPopup()"
        >Thêm vị trí</el-button>
      </div>
    </el-row>
    <div v-loading="callingAPI" class="table">
      <list-positions
        :data="listPositions"
        :loading="loading"
        @edit="handleOpenFormPopup"
        @delete="handleDelete"
      ></list-positions>
    </div>
    <position-form-popup
      v-if="visible"
      :visible="visible"
      :position="position"
      :positions="listPositions"
      @updatePositions="handleUpdatePositions"
      @close="visible = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ListPositions from './positions/List'
import PositionFormPopup from './popups/PositionForm.vue'
import { updateBanner } from '@/services/banner'

import CONSTANTS from '@/config/constants'

export default {
  name: 'PagePositions',
  components: {
    ListPositions,
    PositionFormPopup
  },
  props: {
    page: Object
  },
  data() {
    return {
      loading: false,
      visible: false,
      position: null,
      callingAPI: false,
      positionIds: CONSTANTS.POSITION_IDS
    }
  },
  computed: {
    ...mapGetters(['language']),
    listPositions() {
      return (this.page.meta.positions || []).sort((a, b) => { return a.id - b.id })
    },
    total() {
      return this.listPositions.length
    }
  },

  methods: {
    handleReload() {},
    handleOpenFormPopup(position = null) {
      this.position = position
      this.visible = true
    },
    handleUpdatePositions(ps) {
      updateBanner(this.page.id, {
        id: this.page.id,
        meta: {
          positions: ps
        }
      }).then(() => {
        this.$notify.success({
          title: 'Thành công',
          message: 'Cập nhật thành công'
        })
        this.visible = false
        this.page.meta.positions = ps
      }).catch(() => {
        this.$notify.error({
          title: 'Thất bại',
          message: 'Cập nhật thất bại'
        })
      })
    },
    handleDelete(position) {
      this.$confirm('Bạn có chắc chắn muốn xoá vị trí?', 'Cảnh báo', {
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Hủy',
        type: 'warning'
      }).then(() => {
        const newPs = this.page.meta.positions.filter((p) => p.id !== position.id)
        this.handleUpdatePositions(newPs)
      }).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
