<template>
  <el-table v-loading="loading" :data="data">
    <el-table-column label="STT" type="index" width="100" align="center"></el-table-column>
    <el-table-column label="Giá tiền" prop="price" min-width="200">
      <template slot-scope="scope">
        {{ scope.row.price | numberWithDelimiter }} / 1 Bài viết
      </template>
    </el-table-column>
    <el-table-column label="Ngày sử dụng" prop="days" min-width="300" align="right"></el-table-column>
    <el-table-column label="Thao tác" width="120" align="center" fixed="right">
      <template slot-scope="scope">
        <el-tooltip content="Sửa" placement="top">
          <el-button
            icon="el-icon-edit"
            circle
            type="primary"
            size="small"
            @click="$emit('edit', scope.row)"
          ></el-button>
        </el-tooltip>
        <el-tooltip content="Xóa" placement="top">
          <el-button
            icon="el-icon-delete"
            circle
            type="danger"
            size="small"
            @click="$emit('delete', scope.row)"
          ></el-button>
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: 'ListUser',
  props: {
    data: Array,
    loading: Boolean
  }
}
</script>

<style lang="scss" scoped>
</style>
